<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSortExercise"
        persistent
        max-width="600px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp thứ tự bài tập cho section: {{ section_title }}</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortExercise = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="p-0">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(exercise, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h4 class="mt-0 mb-1 font-weight-bold">
                          {{ exercise.exercise_title }}
                        </h4>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              outlined
              @click="btnSortExercise"
              >Cập nhật</v-btn
            >
            <v-btn color="darken-1" text @click="dialogSortExercise = false"
              >Đóng</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
export default {
  name: "SortExerciseModal",
  components: {
    draggable,
  },
  data() {
    return {
      section_title: "",
      data_draggable: [],
    };
  },
  computed: {
    ...mapState({
      section_id: (state) => state.contentTestBuilderStore.section_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSortExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortExercise;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSortExercise",
          value
        );
      },
    },
  },
  watch: {
    dialogSortExercise: function (val) {
      if (val == true) {
        this.setDataInput();
      }
    },
  },
  methods: {
    setDataInput() {
      let data_section =
        this.skills[this.indexSkill].sections[this.indexSection];
      this.section_title = data_section.section_title;
      this.data_draggable = [...data_section.exercises];
    },
    btnSortExercise() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          let data_sort = vm.data_draggable.map((exercise, index) => {
            return {
              exercise_id: exercise.id,
              offset: index + 1,
            };
          });
          let data = {
            section_id: vm.section_id,
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/exercise/sort", data)
            .then(function (res) {
              if (res.status == 200) {
                for (let iExer = 0; iExer < vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length; iExer++) {
                  for (let iSort = 0; iSort < data_sort.length; iSort++) {
                    if (vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[iExer].id === data_sort[iSort].exercise_id) {
                      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[iExer].offset = data_sort[iSort].offset;
                    }
                  }
                }
                vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.sort(function (x, y) {
                  return x.offset - y.offset;
                });
                vm.skills[vm.indexSkill].sections[vm.indexSection].exercises = vm.refreshExercise(vm.skills[vm.indexSkill].sections[vm.indexSection].exercises);
                vm.$set(vm.skills);
                vm.is_call_api = false;
                vm.$toasted.success("Sort exercise successfully !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                vm.dialogSortExercise = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
            });
        }
      });
    },
    refreshExercise(exercises) {
      for (let iE = 0; iE < exercises.length; iE++) {
        exercises[iE].offset_question = this.recursiveOffsetQuesOfExer(exercises, iE);
      }
      return exercises;
    },
    recursiveOffsetQuesOfExer(exercises, index) {
      let offset = 0;
      if (index == 0) {
        return 0;
      }
      offset = exercises[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfExer(exercises, index - 1);
    },
  },
};
</script>

<style lang="css" scoped>
.drag-css {
  cursor: pointer;
}
</style>
